<template>
    <div class="outContainer">
      <!-- 筛选条件栏 -->
      <div class="search">
        <el-input class="each" placeholder="小区名称" v-model="searchInfo.param.name">
        </el-input>
        <el-select class='each' placeholder="所属商户" v-model="merchantId"
          clearable
          v-if="userInfo.roleLevel < 5">
          <el-option
            v-for="item in merchantOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select class='each' placeholder="是否有地锁" v-model="searchInfo.param.isHaveGroundLock" clearable>
          <el-option
            v-for="item in isHaveGroundLockOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select class='each' placeholder="是否有充电桩" v-model="searchInfo.param.isHaveChargingPile" clearable>
          <el-option
            v-for="item in isHaveChargingPileOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">搜索</el-button>
        <el-button size="mini" type="primary" icon="el-icon-close" @click="handleSearch('clear')">清空搜索条件</el-button>
        <el-button size="mini" @click="openDrawer(null)" v-if="userInfo.roleLevel < 5">新增</el-button>
      </div>
      <!-- 表格数据 -->
      <div class="tableContainer">
        <el-table
         :data="tableData"
         height="100%"
         style="width: 100%;">
          <af-table-column align="center" label="小区名称" prop="name">
          </af-table-column>
          <af-table-column align="center" label="小区地址" prop="addressStr">
          </af-table-column>
          <af-table-column align="center" label="工作人员" prop="userAdminList" v-if="userInfo && userInfo.roleLevel <= 5">
            <template slot-scope="scope">
              <p v-if=" !scope.row.userAdminList || scope.row.userAdminList.length==0">--</p>
              <p v-if="scope.row.userAdminList && scope.row.userAdminList.length == 1" >
                  {{ scope.row.userAdminList[0].account }}
                  <el-button size="mini" @click="releaseBind(scope.row.userAdminList[0],scope.row)">解绑</el-button>
              </p>
              <el-dropdown trigger="click" v-if="scope.row.userAdminList && scope.row.userAdminList.length>1">
                <span class="el-dropdown-link">
                  {{scope.row.userAdminList[0].account}}
                  <el-button size="mini" @click="releaseBind(scope.row.userAdminList[0],scope.row)">解绑</el-button>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown" style="max-height:200px;overflow-y: auto;">
                  <el-dropdown-item v-for="(item,index) in scope.row.userAdminList" :key="index">
                      {{item.account}}
                      <el-button size="mini" @click="releaseBind(item,scope.row)">解绑</el-button>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </af-table-column>
          <af-table-column align="center" label="进出口数量" prop="passageway">
          </af-table-column>
          <af-table-column align="center" label="是否有地锁" prop="isHaveGroundLock">
            <template slot-scope="scope">
              {{ scope.row.isHaveGroundLock == 0 ? '无' : '有' }}
            </template>
          </af-table-column>
          <!-- <af-table-column align="center" label="是否有充电桩" prop="isHaveChargingPile">
            <template slot-scope="scope">
              {{ scope.row.isHaveChargingPile == 0 ? '无' : '有' }}
            </template>
          </af-table-column> -->
          <af-table-column align="center" label="是否开放预约地锁" prop="isOpenEntry">
            <template slot-scope="scope">
              {{ scope.row.isOpenEntry == 0 ? '是' : '否' }}
            </template>
          </af-table-column>
          <af-table-column align="center" label="是否序化停放" prop="isSequentialParking">
            <template slot-scope="scope">
              {{ scope.row. isSequentialParking == 1 ? '是' : '否' }}
            </template>
          </af-table-column>
          <af-table-column align="center" label="创建时间" prop="createTimeStr">
          </af-table-column>
          <el-table-column align='center' label="操作" fixed="right" width="300">
            <template slot-scope="scope">
              <el-button size="mini" @click="handleTemplate(scope.row)" v-if="userInfo.roleLevel <= 5">模板管理</el-button>
              <el-button size="mini" @click="parkList(scope.row)">区域管理</el-button>
              <el-popover
                placement="top"
                trigger="click"
                width="auto"
              >
                  <div style="text-align: right; margin: 0;display: flex;justify-content: space-between;flex-wrap: wrap;">
                    <span class="spanBtn" @click="openDrawer(scope.row)" v-if="userInfo.roleLevel <= 5">修改</span>
                    <span class="spanBtn" @click="handleDel(scope.row)" v-if="userInfo.roleLevel < 5">删除</span>
                    <span class="spanBtn" @click="toExamine(scope.row)">审核列表</span>
                    <span class="spanBtn" @click="handleBind(scope.row)" v-if="userInfo && userInfo.roleLevel <= 5">绑定工作人员</span>
                  </div>
                  <el-button style="margin-left: 10px;" slot="reference" size="mini">更多</el-button>
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <pagination v-bind:child-msg="searchInfo" @callFather="callFather"></pagination>
      <!-- 新增 -->
      <el-drawer
        :title="row ? '编辑' : '新增'"
        :visible.sync="addDrawer"
        :direction="direction"
        :before-close="handleClose"
        size="50%"
      >
        <add v-if="addDrawer" @handleClose='handleClose' ref='newForm' :row="row"></add>
      </el-drawer>
      <!-- 绑定 -->
      <el-drawer
        title="绑定工作人员"
        :visible.sync="bindDrawer"
        :direction="direction"
        :before-close="handleClose"
      >
        <bind v-if="bindDrawer" @handleClose='handleClose' ref='newForm' :row="row"></bind>
      </el-drawer>
      <!-- 设置消费模板 -->
      <el-drawer
        title="设置消费模板"
        :visible.sync="templateDrawer"
        :direction="direction"
        :before-close="handleClose"
        size="50%"
      >
        <templateVue v-if="templateDrawer" @handleClose='handleClose' ref='newForm' :row="row"></templateVue>
      </el-drawer>
    </div>
  </template>
  
  <script>
  import Vue from 'vue';
  import add from './add.vue';
  import bind from './bind.vue';
  import templateVue from './template.vue';
  import pagination from '@/components/Pagination.vue';
  import { getNeighbourhoodList,delNeighbourhood,unbindUserByUnit,getRegionTree,getParentTree,getMerchantList } from '@/api/public.js';
  import { timeChange,removeChildren } from '@/api/publicFun.js';
  export default {
    data() {
      return {
          searchInfo:{ // 查询数据筛选条件
            pageNo:1,
            pageSize:10,
            total:10,
            param:{
              name:null,
              merchantId:null,
            }
          },
          merchantId:"",
          tableData:[],// 列表数据
          row:null,
          addDrawer:false,
          bindDrawer:false,
          templateDrawer:false,
          direction:"rtl",
          cityOptions:[],
          merchantOptions:[], // 商户选项
          pcaInfo:'',
          userInfo:JSON.parse(localStorage.getItem('userInfo')),
          isHaveGroundLockOptions:[ // 是否有地锁
            {id:0,name:'没有'},
            {id:1,name:'有'}
          ],
          isHaveChargingPileOptions:[ // 是否有充电桩
            {id:0,name:'没有'},
            {id:1,name:'有'}
          ],
      }
    },
  
    components: {add,bind,pagination,templateVue},
  
    computed: {},
  
    mounted() {
      this.init();
      this.getMerchantOptions();
    },
  
    methods: {
      // 搜索事件
      handleSearch(type){
        if(type == 'clear'){
          this.searchInfo.param = {
            name:null,
            merchantId:null,
          };
          this.merchantId = "";
        }else{
          this.searchInfo.param.merchantId = this.merchantId == '' && this.merchantId != 0 ? null : this.merchantId;
        }
        this.searchInfo.pageNo = 1;
        this.init();
      },
      // 初始化获取列表
      async init(){
        await getNeighbourhoodList(this.searchInfo).then(res=>{
          if(res.success){
            this.tableData = res.data.list;
            this.searchInfo.total = res.data.total;
            this.tableData.forEach(async (element,index) => {
              element.createTimeStr = element.createTime ? timeChange(element.createTime,'seconds') : '--';
              await this.getParentPca(element.regionId).then( addressRes =>{
                element.addressStr = addressRes + element.address;
                Vue.set(this.tableData, index, element);
              })
            });
          }else{
            this.tableData = [];
            this.searchInfo.total = 0;
            this.$message({
              message:res.msg,
              type:'fail'
            })
          }
        })
      },
      // 获取商户列表
      async getMerchantOptions(){
        let data = {
          pageNo:1,
          pageSize:1000,
          param:{
            name:'',
          }
        }
        await getMerchantList(data).then(res=>{
          if(res.success){
            this.merchantOptions = res.data.list;
          }else{
            this.merchantOptions = [];
          }
        })
      },
      // 分页插件事件
      callFather(parm) {
        this.searchInfo.pageNo = parm.currentPage;
        this.init();
      },
      // 获取省市区联动数据
      async getPCA(){
        await getRegionTree().then(res=>{
          if(res.success){
            this.cityOptions = res.data;
            this.cityOptions.forEach(element => {
              element = removeChildren(element);
            });
          }else{
            this.cityOptions = [];
          }
        })
      },
      // 省市区：通过子级id获取父级id
      getParentPca(id){
        let param = new URLSearchParams();
        param.append("param", id);
        return new Promise((resolve) => {
          getParentTree(param).then( res =>{
            if(res.success){
              let all = res.data;
              let address = this.digui(all,[],"");
              resolve(address);
            }
          })
        })
      },
      // 递归
      digui(obj,regionArr,address){
        regionArr.unshift(obj.id);
        address = obj.name + address;
        if(obj.parent){
          return this.digui(obj.parent,regionArr,address);
        }else{
          return address;
        }
      },
      // 打开绑定
      handleBind(row){
        this.row=row
        this.bindDrawer=true;
      },
      // 打开设置消费模板弹框
      handleTemplate(row){
        this.row = row;
        this.templateDrawer = true;
      },
      // 关闭弹框
      handleClose(){
        this.bindDrawer = false;
        this.addDrawer = false;
        this.templateDrawer = false;
        this.row = null;
        this.init();
      },
      // 打开审核列表
      toExamine(row){
        this.$router.push({path: "/neighbourhoodManage/examineList", query: {neighborInfoId: row.id}})
      },
      // 打开停车列表
      parkList(row){
        this.$router.push({path: "/neighbourhoodManage/parkList", query: {neighborInfoId: row.id,merchantId:row.merchantId}})
      },
      // 删除
      handleDel(row){
      this.$confirm('确认删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        delNeighbourhood({param:row.id}).then(res=>{
          if(res.success){
            this.$message({
              message:'删除成功',
              type:'success',
            })
            this.init();
          }else{
            this.$message({
              message:res.msg,
              type:'fail',
            })
          }
        })
      })
      },
      // 解绑管理员
      releaseBind(userRow,row){
        event.stopPropagation();
        this.$confirm('确认进行解绑吗？').then(async ()=>{
          let data = {
            userId:userRow.id,
            unitId:row.id,
          }
          await unbindUserByUnit({param:data}).then(res=>{
            if(res.success){
              this.$message({
                message:'解绑成功',
                type:'success'
              });
              this.init();
            }else{
              this.$message({
                message:res.msg,
                type:'fail'
              });
            }
          })
        })
      },
      openDrawer(row){
        this.row = row;
        this.addDrawer = true;
      },
    },
  };
  </script>
  <style scoped lang="scss">
  .el-popover{
    width: 240px !important;
  }
  .spanBtn{
    width: 100%;
    cursor: pointer;
    display: inline-block;
    color: #40a9ff;
    text-align: center;
    padding-bottom: 5px;
  }
  </style>
  