<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="220px" style="width:95%;overflow:auto">
        <el-form-item label="小区名称" prop="name" >
          <el-input placeholder="请输入小区名称" v-model="form.name" :disabled="row && userInfo.roleLevel >= 5 ? true : false"></el-input>
        </el-form-item>
        <el-form-item label="所属省/市/区/街道/社区" prop="regionId">
          <el-cascader
            style="width: 100%;"
            clearable
            placeholder="请选择"
            v-model="form.regionId"
            :options="cityOptions"
            :props="{ expandTrigger: 'click',value:'id',label:'name',children:'childrenList' }"
            @change="chooseCityStreet"
            :disabled="row && userInfo.roleLevel >= 5 ? true : false"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input placeholder="请填写除省市区、社区、街道外的详细地址" v-model="form.address" :disabled="row && userInfo.roleLevel >= 5 ? true : false"></el-input>
        </el-form-item>
        <el-form-item label="所属商户" prop="merchantId">
          <el-select v-model="form.merchantId" clearable placeholder="所属商户" :disabled="row ? true : false">
            <el-option
              v-for="item in merchantOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="进出口数量" prop="passageway" >
          <el-input placeholder="请输入进出口数量" v-model="form.passageway">
          </el-input>
        </el-form-item>
        <el-form-item label="小区范围" prop="ranges">
          <button class="bMap" type="button"  @click="handleSetNeighborRange">地图划定</button>
        </el-form-item>
        <!-- <el-form-item label="是否有充电桩" prop="isHaveChargingPile">
           <el-radio-group v-model="form.isHaveChargingPile">
             <el-radio-button :label="1">有</el-radio-button>
             <el-radio-button :label="0">无</el-radio-button>
           </el-radio-group>
        </el-form-item> -->
        <el-form-item label="是否有地锁" prop="isHaveGroundLock">
           <el-radio-group v-model="form.isHaveGroundLock">
             <el-radio-button :label="1">有</el-radio-button>
             <el-radio-button :label="0">无</el-radio-button>
           </el-radio-group>
        </el-form-item>
        <el-form-item label="是否开放预约地锁" prop="isOpenEntry">
           <el-radio-group v-model="form.isOpenEntry">
             <el-radio-button :label="1">否</el-radio-button>
             <el-radio-button :label="0">是</el-radio-button>
           </el-radio-group>
        </el-form-item>
        <el-form-item label="是否序化停放" prop="isSequentialParking">
           <el-radio-group v-model="form.isSequentialParking">
             <el-radio-button :label="1">是</el-radio-button>
             <el-radio-button :label="0">否</el-radio-button>
           </el-radio-group>
        </el-form-item>
        <el-form-item label="新能源充电桩安装时间" prop="carInstallTime">
          <el-date-picker
            :clearable="isChooseDateClear"
            :editable="isChooseDateClear"
            v-model="form.carInstallTime"
            type="date"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="新能源充电桩投入成本（元）" prop="carInputCosts">
          <el-input placeholder="请输入新能源充电桩投入成本" v-model="form.carInputCosts"></el-input>
        </el-form-item>
        <el-form-item label="车位数量" prop="carPlaceQuantity">
          <el-input placeholder="请输入车位数量" v-model="form.carPlaceQuantity"></el-input>
        </el-form-item>
        <el-form-item label="新能源充电桩分润占比（%）" prop="carProfitSharingRatio">
          <el-input placeholder="请输入新能源充电桩分润占比" v-model="form.carProfitSharingRatio"></el-input>
        </el-form-item>
        <el-form-item label="电瓶车充电桩安装时间" prop="bikeInstallTime">
          <el-date-picker
            :clearable="isChooseDateClear2"
            :editable="isChooseDateClear2"
            v-model="form.bikeInstallTime"
            type="date"
            placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="电瓶车充电桩投入成本（元）" prop="bikeInputCosts">
          <el-input placeholder="请输入电瓶车充电桩投入成本" v-model="form.bikeInputCosts"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="close('form')">取消</el-button>
          <el-button type="primary" @click="submit('form')" :plain='true'>提交</el-button>
        </el-form-item>
      </el-form>
       <!-- 地图 -->
       <el-dialog title="设置小区范围" :visible.sync="open" width="900px" append-to-body>
        <el-form label-width="80px">
          <el-row>
            <el-col :span="10">
              <el-form-item label="搜索地址">
                <el-input type="text" id="searchAddress" v-model="searchAddress" placeholder="请输入地址"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- 百度地图 -->
        <div id="map-container" style="width: 100%; height: 400px;"></div>
        <!-- 按钮 -->
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="confirm">确定</el-button>
          <el-button @click="cancel">重置</el-button>
        </div>
      </el-dialog>
    </section>
  </template>
  
  <script>
  import { addNeighbourhood,updateNeighbourhood,getRegionTree,getParentTree,getMerchantList } from '@/api/public.js';
  import { MakeMaxNumRule,getPointByAddress,removeChildren,getCascaderLabel } from '@/api/publicFun.js';

  export default {
    props:{row:{type:Object}},
    data() {
      return {
        form:{ // 表单数据
          address: "",
          name: "",
          regionId:[],
          latitude:'',
          longitude:'',
          passageway:0,
          ranges:'',
          carInstallTime:"",
          carInputCosts:"",
          carPlaceQuantity:"",
          carProfitSharingRatio:"",
          bikeInstallTime:"",
          bikeInputCosts:"",
        },
        pcaInfo:'',
        cityOptions:[], // 省市区选项
        merchantOptions:[], // 商户选项
        rules: { // 表单验证
          name: [
            { required: true, message: '小区名称不得为空', trigger: 'blur' }
          ],
          ranges: [
            { required: true, message: '请划定小区范围', trigger: 'change' }
          ],
          passageway: [
            { required: true, message: '进出口数量不得为空', trigger: 'blur' },
            { validator: MakeMaxNumRule, trigger: 'blur' },
          ],
          regionId:[
            { required: true, message: '请选择小区所在省/市/区/街道/社区', trigger: 'blur' },
          ],
          address:[
            { required: true, message: '详细地址不得为空', trigger: 'blur' },
          ],
          merchantId:[
            { required: true, message: '所属商户不得为空', trigger: 'blur' },
          ],
          isOpenEntry:[
            { required: true, message: '请选择是否开放预约地锁', trigger: 'change' },
          ],
          isHaveChargingPile:[
            { required:true, message: '请选择是否有充电桩', trigger: 'change'},
          ],
          isHaveGroundLock:[
            { required:true, message: '请选择是否有地锁', trigger: 'change'}
          ],
          isSequentialParking:[
            { required:true, message: '请选择是否序化停放', trigger: 'change'}
          ],
        },
        userGps:["120.17906278816268","30.32004063996321"], // 用户当前位置
        // 地址信息
        addressInfo: {
          longitude: "",// 经度
          latitude: "",// 纬度
          province: "",// 省
          city: "",// 市
          district: "",// 区
          address: "",// 详细地址
        },
        open:false, // 是否显示地图
        Polygon:[], // 多边形各个顶点的Gps列表
        map:null, // 地图实例
        searchAddress:"",
        userInfo:JSON.parse(localStorage.getItem('userInfo')),
        isChooseDateClear:false,
        isChooseDateClear2:false,
      };
    },
  
    components: { },
  
    computed: {},
  
    mounted() {
      this.getLocation();
      this.isChooseDateClear = true;
      this.isChooseDateClear2 = true;
      if(this.row){ // row存在则说明为编辑，数据需要适当转换便于回显
        this.form = {...this.row};
        this.form.createTime = null;
        this.form.ranges = this.form.ranges ? JSON.parse(this.form.ranges) : this.form.ranges;
        this.getParentPca(this.form.regionId);
        this.isChooseDateClear = this.form.carInstallTime ? false : true;
        this.isChooseDateClear2 = this.form.bikeInstallTime ? false : true;
      }
      this.getPCA();
      this.getMerchantOptions();
    },
  
    methods: {
      // 获取商户列表
      async getMerchantOptions(){
        let data = {
          pageNo:1,
          pageSize:1000,
          param:{
            name:'',
          }
        }
        await getMerchantList(data).then(res=>{
          if(res.success){
            this.merchantOptions = res.data.list;
          }else{
            this.merchantOptions = [];
          }
        })
      },
      // 获取省市区联动数据
      async getPCA(){
        await getRegionTree().then(res=>{
          if(res.success){
            this.cityOptions = res.data;
            this.cityOptions.forEach(element => {
              element = removeChildren(element);
            });
          }else{
            this.cityOptions = [];
          }
        })
      },
      // 省市区：通过子级id获取父级id
      async getParentPca(id){
        let param = new URLSearchParams();
        param.append("param", id);
        await getParentTree(param).then(res=>{
          if(res.success){
            let all = res.data;
            this.digui(all,[],"")
          }
        })
      },
      // 递归
      digui(obj,regionArr,address){
        regionArr.unshift(obj.id);
        address = obj.name + address;
        if(obj.parent){
          this.digui(obj.parent,regionArr,address)
        }else{
          this.form.regionId = regionArr;
          this.pcaInfo = address;
        }
      },
      // 选择省市区
      chooseCityStreet(e){
        if(e){
          let text = "";
          this.pcaInfo = getCascaderLabel([...e],this.cityOptions,text);
        }
      },
      // 获取用户当前位置
      getLocation(){
        var geolocation = new BMapGL.Geolocation();
        geolocation.getCurrentPosition((r)=>{   
          this.userGps[0]=r.longitude;
          this.userGps[1]=r.latitude;
        },{enableHighAccuracy: false,maximumAge:1000})
      },
      // 关闭弹窗
      close(){
         this.$emit('handleClose');
      },
      // 新增
      add(){
        addNeighbourhood({param:this.form}).then(res=>{
          if(res.success){
            this.$message({
              message:'提交成功',
              type:'succcess',
            })
            setTimeout(()=>{
              this.$emit('handleClose');
            },1000)
          }else{
            this.$message({
              message:res.msg,
              type:'fail',
            })
            setTimeout(()=>{
              this.$emit('handleClose');
            },1000)
          }
        })
      },
      // 编辑
      edit(){
        updateNeighbourhood({param:this.form}).then(res=>{
          if(res.success){
            this.$message({
              message:'提交成功',
              type:'succcess',
            })
            setTimeout(()=>{
              this.$emit('handleClose');
            },1000)
          }else{
            this.$message({
              message:res.msg,
              type:'fail',
            })
          }
        })
      },
      // 提交
      submit(form) {
        // let address = this.pcaInfo+this.form.address;
        // getPointByAddress(address).then(res=>{
        //   this.form.latitude = res.lat;
        //   this.form.longitude = res.lng;
        // });
        this.$refs["form"].validate(valid => {
          if(valid){
            let inde = this.form.regionId.length - 1;
            this.form.regionId = this.form.regionId[inde];
            this.form.ranges = JSON.stringify(this.form.ranges);
            this.form.passageway = Number(this.form.passageway)
            this.$refs["form"].validate(valid => {
                if (valid) {
                  this.$confirm('确认提交吗?','提示',{
                    confirmButtonText:'确定',
                    cancelButtonText:'取消',
                    type:'warning'
                  }).then(()=>{
                    if(this.row){
                      this.edit();
                    }else{
                      this.add();
                    }
                  })
                } else {
                  return false;
                }
            });
          }
        })
        
      },
      // 打开地图设置小区范围
      handleSetNeighborRange(){
        this.open=true;
        this.initBaiduMap();
      },
      // 初始化百度地图
      initBaiduMap() {
        let that = this;
        this.$nextTick(() => {
          /* 初始化地图 start */
          this.map = this.map ? this.map : new BMapGL.Map("map-container",{
            style: {
              styleJson: [
                {
                  featureType: "building",
                  elementType: "all",
                  stylers: {
                    visibility: "off",
                  },
                },
              ],
            },
          }); // 创建地图实例
          let Gps;
          if(this.form.ranges && this.form.ranges.length > 0){ // 如果该小区已经有gps范围，则先描绘出gps的范围
            this.map.clearOverlays();
            let arr=[];
            for(let i=0;i<this.form.ranges.length;i++){
              arr.push(new BMapGL.Point(this.form.ranges[i][0],this.form.ranges[i][1]));
            }
            Gps= this.form.ranges[0];
            var polygon = new BMapGL.Polygon(arr, {strokeColor:"blue", strokeWeight:2, strokeOpacity:0.5});  //创建多边形
            this.map.addOverlay(polygon);  //增加多边形
          }else{
            if(this.row){
              Gps = [this.row.longitude,this.row.latitude];
            }else{
              Gps = this.userGps; 
            }
          }
          var point = new BMapGL.Point(Gps[0],Gps[1]); // 设置中心点坐标
          this.map.centerAndZoom(point, 18); // 地图初始化，同时设置地图展示级别
          this.map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
          var marker = new BMapGL.Marker(point); // 创建标注
          this.map.addOverlay(marker); // 将标注添加到地图中
          /* 初始化地图 end */
          this.map.addEventListener("click", (e) => {
            this.$nextTick(()=>{
              var click = e.latlng; // 点击的坐标
              this.Polygon.push(click);
              if(this.Polygon.length<=1){
                this.map.clearOverlays();
                var Point = new BMapGL.Point(click.lng,click.lat);
                var Marker = new BMapGL.Marker(Point); // 创建标注
                this.map.addOverlay(Marker); // 将标注添加到地图中
                that.geocAddress(Point);
              }else if(this.Polygon.length == 2){
                let start=this.Polygon[this.Polygon.length-2];
                let end=this.Polygon[this.Polygon.length-1];
                var polyline = new BMapGL.Polyline([
                  new BMapGL.Point(start.lng, start.lat),
                  new BMapGL.Point(end.lng, end.lat),
                ], {strokeColor:"blue", strokeWeight:2, strokeOpacity:0.5});   //创建折线
                this.map.addOverlay(polyline)
              }else{
                this.map.clearOverlays()
                let arr=[];
                this.Polygon.forEach(element => {
                  arr.push(new BMapGL.Point(element.lng,element.lat));
                });
                var polygon = new BMapGL.Polygon(arr, {strokeColor:"blue", strokeWeight:2, strokeOpacity:0.5});  //创建多边形
                this.map.addOverlay(polygon);  //增加多边形
              }
            })
          })
          /** 点击地图创建坐标事件End */
          /** 搜索地址Start */
          // 建立一个自动完成的对象
          var ac = new BMapGL.Autocomplete({
              input: "searchAddress",
              location: this.map,
            });
          // 鼠标点击下拉列表后的事件
          ac.addEventListener("onconfirm", (e) => {
            this.map.clearOverlays();
            var local = new BMapGL.LocalSearch(this.map, {
              // 智能搜索
              onSearchComplete: (res) => {
                let poi = res.getPoi(0); // 获取第一个智能搜索的结果
                var searchpt = poi.point; // 获取坐标
                this.map.centerAndZoom(searchpt, 16);
                this.map.addOverlay(new BMapGL.Marker(searchpt));
                that.geocAddress(searchpt);
              }
            });
            // 搜索词
            var searchValue = e.item.value;
            local.search(
              searchValue.province +
              searchValue.city +
              searchValue.district +
              searchValue.street +
              searchValue.business
            )
          });
          /** 搜索地址End */
        })
      },
      // 逆向解析地址
      geocAddress(point) {
          let that = this;
          var geoc = new BMapGL.Geocoder();
          geoc.getLocation(point,  (geocInfo) => {
            // 设置基本信息
            var addressInfo = geocInfo.addressComponents;
            that.addressInfo.longitude = point.lng;
            that.addressInfo.latitude = point.lat;
            that.addressInfo.province = addressInfo.province;
            that.addressInfo.city = addressInfo.city;
            that.addressInfo.district = addressInfo.district;
            let address = addressInfo.street + addressInfo.streetNumber;
            if (geocInfo.surroundingPois.length > 0) {
              address = address + geocInfo.surroundingPois[0].title;
            }
            that.addressInfo.address = address;
          });
      },
      // 确认设置小区范围
      confirm() {
        let points = [];
        for(let i=0;i<this.Polygon.length;i++){
          points.push({lng:this.Polygon[i].lng,lat:this.Polygon[i].lat})
          this.Polygon[i]=[""+this.Polygon[i].lng,""+this.Polygon[i].lat];
        }
        if(points.length){ // 根据小区的范围，计算一个比较舒适的缩放范围
            var view = this.map.getViewport(eval(points));
            this.form.latitude = view.center.lat;
            this.form.longitude = view.center.lng;
        }
        this.form.ranges = this.Polygon;
        this.open = false;
      },
      // 取消设置小区范围
      cancel() {
        this.Polygon = [];
        this.form.ranges = '';
        this.map.clearOverlays();
      },
    }
  };
  </script>
  <style lang="scss" scoped>
  .bMap{
    border: 1px solid #dcdfe6;
    background: #fff;
    border-radius: 4px;
    width: 100px;
    height: 35px;
   }
   .map{
    border: 1px solid #dcdfe6;
    background: #fff;
    border-radius: 4px;
    width: 80px;
   }
   /deep/ .el-form-item__content{
    display: flex;
   }
   .el-drawer{
     overflow:auto !important;
   }
   .avatar-uploader .el-upload {
      cursor: pointer;
      position: relative;
      overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
      border-color: #409EFF;
    }
    .avatar-uploader-icon {
      border: 1px dashed #ccc;
      border-radius: 50%;
      overflow:hidden;
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
  </style>
  